export const METHOD = {
  GET: "get",
  POST: "post",
  BLOB: "blob",
}

export const ENDPOINT = {
  CALCULATE: {
    METHOD: METHOD.POST,
    URL: "/evoli/quote/calculate",
  },
  // FETCH_DOCUMENT: {
  //   METHOD: METHOD.GET,
  //   URL: "/document/get/api-html/",
  // },
  FETCH_DOCUMENT: {
    METHOD: METHOD.BLOB,
    URL: "/document/get/stream/",
  },
  SAVE_QUOTE_SEND_PIN: {
    METHOD: METHOD.POST,
    URL: "/evoli/quote/save-quote-send-pin",
  },
  PURCHASE_QUOTE: {
    METHOD: METHOD.POST,
    URL: "/pending/purchase-quote",
  },
  CREATE_ACCEPT: {
    METHOD: METHOD.POST,
    URL: "/evoli/quote/create-accept",
  },
  VERIFY_EMAIL: {
    METHOD: METHOD.POST,
    URL: "/evoli-sec/verify-email",
  },
  GENERATE_PIN: {
    METHOD: METHOD.POST,
    URL: "/pending/generate-pin",
  },
  GET_QUOTE: {
    METHOD: METHOD.GET,
    URL: "/pending/get-quote/",
  },
  SELECT_PAYMENT: {
    METHOD: METHOD.POST,
    URL: "/pending/select-payment",
  },
  ADD_EMAIL: {
    METHOD: METHOD.POST,
    URL: "/evoli-sec/add-email",
  },
}
