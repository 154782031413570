import * as Sentry from "@sentry/browser"
import { CleanSSN } from "./HelperFunctions"

export const sentryCalcFailed = state => {
  let pers_nr = CleanSSN(state.fields.socialSecurity.fieldValue)

  Sentry.captureException(
    new Error(
      `CALCULATE_FAILED |  REGNR: ${
        state.fields.registrationNumber.fieldValue
      } | PERSNR: ${pers_nr.substring(0, pers_nr.length - 4)}**** | EXCESS: ${
        state.coverList.excessLevel
      } | MILAGE: ${state.coverList.mileage} | YOUNG_DRIVER: ${
        state.additions.youngDriver.selected
      }`
    )
  )
}

export const sentryDocumentFailed = (id, err) => {
  Sentry.captureException(`Document failed: ${id} | ${err}`)
}
