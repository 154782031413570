export const defaultTheme = {
  colors: {
    primaryBackground: "#ffffff",
    secondaryBackground: "#faf8ff",
    primaryTextColor: "#6569a6",
    primaryHeaderColor: "#ff4984",
    PrimaryFieldLabel: "#5558ea",
    PrimaryH1: "#ff4984",
    HeroBackground:
      "linear-gradient(to bottom, rgba(255, 255, 255, 0.5), #f9f9fb);",

    header: {
      // headerBackground: "linear-gradient(to bottom, #6739e0, #5737d5)",
      headerColor: "#4b4b65",
      headerLogoColor: "#5558ea",
    },

    Accordion: {
      buttonBackground: "#5b37d8",
      buttonColor: "#e4e4e4",
      buttonHover: "#6f4de6",
      textBackground: "#d6cef0",
      chevronColor: "#ffffff",
    },

    HeroTextBox: {
      color: "#4b4b65",
    },

    FullWidthContainer: {
      background: "#faf8ff",
      color: "#70669b",
      headingColor: "#ff4984",
    },

    CalculateTextBox: {
      background: "#ffffff",
      colorValid: "#01cc78",
      colorDefault: "#6f72ff",
      colorError: "#ff4984",
      boxShadow: "1px 1px 3px #69696982;",
    },

    quoteSubHero: {
      background: "#f7f7ff",
      headingColor: "#5558ea",
      color: "#6569a6",
    },

    CardContainer: {
      cardSectionBackground: "#ffffff",
      background: "#ffffff",
      heading: "#ff4984",
      subHeading: "#5558ea",
      button: "#01cc86",
      boxShadow: "0 0 22px 2px rgba(170, 169, 173, 0.2)",
      iconBackground: "#c4ffe1",
      iconColor: "#5558ea",
      secondaryIconBackground: "#5558ea",
      secondaryIconColor: "#47ecb4",
    },

    TextImageSection: {
      background: "#ffffff",
    },

    menu: {
      menuBackground: "#e9d7ef",
      menuColor: "#70669b",
      menuColorHover: "#564a8c",
      burgerColor: "#4b4b65",
      burgerColorOpen: "#70669b",
    },

    loader: {
      background: "#ffffff",
      color: "#5558ea",
    },

    Footer: {
      background: "#151625",
      logoColor: "#47ecb4",
      linkHeader: "#ffffff",
      linkColor: "#47ecb4",
      hoverColor: "#f15b8a",
    },
  },
  sizes: {
    mobile: "576px",
  },
}

export const darkTheme = {
  colors: {
    primaryBackground: "#000000",
    secondaryBackground: "#0c0c0c",
    primaryTextColor: "#6569a6",
    primaryHeaderColor: "#ff4984",
    PrimaryFieldLabel: "#5558ea",
    PrimaryH1: "#ff4984",
    HeroBackground: "#000000",

    header: {
      headerBackground: "linear-gradient(to bottom, #000000, #000000)",
      headerColor: "#5558ea",
      headerLogoColor: "#5558ea",
    },

    Accordion: {
      buttonBackground: "#0c0c0c",
      buttonColor: "#ff4984",
      buttonHover: "#0c0c0ce6",
      textBackground: "#0f0f0f",
      chevronColor: "#777777",
    },

    FullWidthContainer: {
      background: "#0c0c0c",
      color: "#6569a6",
      headingColor: "#ff4984",
    },

    HeroTextBox: {
      color: "#70669b",
    },

    CalculateTextBox: {
      background: "#171717",
      colorValid: "#01cc78",
      colorDefault: "#6f72ff",
      colorError: "#ff4984",
      boxShadow: "none",
    },

    quoteSubHero: {
      background: "#0c0c0c",
      headingColor: "#5558ea",
      color: "#6569a6",
    },

    CardContainer: {
      cardSectionBackground: "#000000",
      background: "#0c0c0c",
      boxShadow: "none",
      heading: "#70669b",
      subHeading: "#5558ea",
      button: "#01cc86",
      iconBackground: "#5558ea",
      iconColor: "#47ecb4",
      secondaryIconBackground: "#5558ea",
      secondaryIconColor: "#47ecb4",
    },

    TextImageSection: {
      background: "#000000",
    },

    menu: {
      menuBackground: "#0c0c0c",
      menuColor: "#5558ea",
      menuColorHover: "#d0d0d0",
      burgerColor: "#5558ea",
      burgerColorOpen: "#5558ea",
    },

    loader: {
      background: "#272626",
      color: "#01cc94",
    },

    Footer: {
      background: "#0c0c0c",
      logoColor: "#5558ea",
      linkHeader: "#ff4984",
      linkColor: "#5558ea",
      hoverColor: "#7073e8",
    },
  },
  sizes: {
    mobile: "576px",
  },
}
