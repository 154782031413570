import axios from "axios"
import { METHOD } from "./endpoints"

const axiosInstance = axios.create({
  baseURL: process.env.API_ENDPOINT,
  timeout: 25000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache, no-store",
    apiKey: `${process.env.API_KEY}`,
  },
})

export async function DispatchRequest(ENDPOINT, data) {
  try {
    switch (ENDPOINT.METHOD) {
      case METHOD.GET:
        return await axiosInstance.get(`${ENDPOINT.URL}${data}`)

      case METHOD.POST:
        return await axiosInstance(ENDPOINT.URL, {
          method: ENDPOINT.METHOD,
          data,
        })

      case METHOD.BLOB:
        return await axiosInstance(`${ENDPOINT.URL}${data}`, {
          responseType: "blob",
        })

      default:
        break
    }
  } catch (error) {
    if (error.response) {
      return error.response
    } else if (error.request) {
      return error.request
    }
  }
}
