export const isDarkTheme = () => {
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    return true
  }
  return false
}

export const getTheme = () => {
  if (typeof window === "undefined") {
    return false
  }

  if (window.localStorage.getItem("darkMode") == null) {
    return isDarkTheme()
  }

  return JSON.parse(window.localStorage.getItem("darkMode"))
}

export const getCookieConsent = () => {
  if (typeof window === "undefined") {
    return true
  }

  if (window.localStorage.getItem("accepted-cookies") == null) {
    return false
  }

  return JSON.parse(window.localStorage.getItem("accepted-cookies"))
}
