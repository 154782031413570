export const actionTypes = {
  UPDATE_FIELD: "UPDATE_FIELD",
  UPDATE_COVERLIST: "UPDATE_COVERLIST",
  FETCHING_DATA: "FETCHING_DATA",
  UPDATE_QUOTE: "UPDATE_QUOTE",
  FETCHING_FAILED: "FETCHING_FAILED",
  CALCULATE_QUOTE: "CALCULATE_QUOTE",
  TOGGLE_MENU: "TOGGLE_MENU",
  ACCEPT_QUOTE: "ACCEPT_QUOTE",
  VERIFY_MOBILE_PIN: "VERIFY_MOBILE_PIN",
  REQUEST_MOBILE_PIN: "REQUEST_MOBILE_PIN",
  MODAL_FETCHING_FAILED: "MODAL_FETCHING_FAILED",
  MODAL_RESET_ERROR: "MODAL_RESET_ERROR",
  FORM_FETCHING_FAILED: "FORM_FETCHING_FAILED",
  FORM_RESET_ERROR: "FORM_RESET_ERROR",
  SAVE_QUOTE_SEND_PIN: "SAVE_QUOTE_SEND_PIN",
  TOGGLE_DISPATCHED_PIN: "TOGGLE_DISPATCHED_PIN",
  TOGGLE_COVERLEVEL: "TOGGLE_COVERLEVEL",
  LOAD_QUOTE: "LOAD_QUOTE",
  HAS_FETCHED_QUOTE: "HAS_FETCHED_QUOTE",
  TOGGLE_ADDITIONS: "TOGGLE_ADDITIONS",
  INIT_FETCH_QUOTE: "INIINIT_FETCH_QUOTET_QUOTE",
  INIT_FETCH_QUOTE_DONE: "INIT_FETCH_QUOTE_DONE",
  VERIFY_EMAIL: "VERIFY_EMAIL",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAILED: "VERIFY_EMAIL_FAILED",
  GENERATE_MOBILE_PIN: "GENERATE_MOBILE_PIN",
  COOKIE_CONSENT: "COOKIE_CONSENT",
  LOAD_DOCUMENT: "LOAD_DOCUMENT",
  LOAD_QUOTE_FAILED: "LOAD_QUOTE_FAILED",
  FETCHED_QUOTE: "FETCHED_QUOTE",
  LOAD_DOCUMENT_FAILED: "LOAD_DOCUMENT_FAILED",
  LOAD_DOCUMENT_SUCCESS: "LOAD_DOCUMENT_SUCCESS",
  GENERATE_PIN: "GENERATE_PIN",
  RECALC_FAILED: "RECALC_FAILED",
  YOUNG_DRIVER_FAILED: "YOUNG_DRIVER_FAILED",
  MILEAGE_FAILED: "MILEAGE_FAILED",
  DEDUCTABLE_CONCEPT_FAILED: "DEDUCTABLE_CONCEPT_FAILED",
  EXCESS_LEVEL_FAILED: "EXCESS_LEVEL_FAILED",
  TOGGLE_PAYMENT_INTERVAL: "TOGGLE_PAYMENT_INTERVAL",
}

export const generatePinCode = payload => ({
  type: actionTypes.GENERATE_PIN,
  payload,
})

export const verifyEmailFailed = payload => ({
  type: actionTypes.VERIFY_EMAIL_FAILED,
  payload,
})

export const calculateQuote = payload => ({
  type: actionTypes.CALCULATE_QUOTE,
  payload,
})

export const acceptQuote = payload => ({
  type: actionTypes.ACCEPT_QUOTE,
  payload,
})

export const updateField = payload => ({
  type: actionTypes.UPDATE_FIELD,
  payload,
})
export const updateCoverList = payload => ({
  type: actionTypes.UPDATE_COVERLIST,
  payload,
})

export const fetchingData = () => ({
  type: actionTypes.FETCHING_DATA,
})

export const fetchedQuote = () => ({
  type: actionTypes.FETCHED_QUOTE,
})

export const initFetchQuote = payload => ({
  type: actionTypes.INIT_FETCH_QUOTE,
  payload,
})

export const initFetchQuoteDone = () => ({
  type: actionTypes.INIT_FETCH_QUOTE_DONE,
})

export const updateQuote = quote => ({
  type: actionTypes.UPDATE_QUOTE,
  quote,
})

export const fetchingFailed = error => ({
  type: actionTypes.FETCHING_FAILED,
  error,
})
export const recalcFailed = payload => ({
  type: actionTypes.RECALC_FAILED,
  payload,
})

export const mileageFailed = payload => ({
  type: actionTypes.MILEAGE_FAILED,
  payload,
})

export const deductableConceptFailed = payload => ({
  type: actionTypes.DEDUCTABLE_CONCEPT_FAILED,
  payload,
})
export const youngDriverFailed = payload => ({
  type: actionTypes.YOUNG_DRIVER_FAILED,
  payload,
})

export const excessLevelFailed = payload => ({
  type: actionTypes.EXCESS_LEVEL_FAILED,
  payload,
})
export const formFetchingFailed = error => ({
  type: actionTypes.FORM_FETCHING_FAILED,
  error,
})
export const modalFetchingFailed = error => ({
  type: actionTypes.MODAL_FETCHING_FAILED,
  error,
})
export const formResetError = () => ({
  type: actionTypes.FORM_RESET_ERROR,
})
export const modalResetError = () => ({
  type: actionTypes.MODAL_RESET_ERROR,
})

export const toggleMenu = open => ({
  type: actionTypes.TOGGLE_MENU,
  open,
})

export const verifyMobilePin = payload => ({
  type: actionTypes.VERIFY_MOBILE_PIN,
  payload,
})

export const verifyEmail = payload => ({
  type: actionTypes.VERIFY_EMAIL,
  payload,
})

export const requestMobilePin = payload => ({
  type: actionTypes.REQUEST_MOBILE_PIN,
  payload,
})

export const generateMobilePin = payload => ({
  type: actionTypes.GENERATE_MOBILE_PIN,
  payload,
})

export const loadQuote = payload => ({
  type: actionTypes.LOAD_QUOTE,
  payload,
})

export const loadQuoteFailed = payload => ({
  type: actionTypes.LOAD_QUOTE_FAILED,
  payload,
})

export const toggleHasFetchedQuote = payload => ({
  type: actionTypes.HAS_FETCHED_QUOTE,
  payload,
})

export const loadDocument = payload => ({
  type: actionTypes.LOAD_DOCUMENT,
  payload,
})

export const loadDocumentFailed = payload => ({
  type: actionTypes.LOAD_DOCUMENT_FAILED,
  payload,
})

export const loadDocumentSuccess = payload => ({
  type: actionTypes.LOAD_DOCUMENT_SUCCESS,
  payload,
})

export const saveQuoteSendPin = payload => ({
  type: actionTypes.SAVE_QUOTE_SEND_PIN,
  payload,
})

export const toggleDispatchedPin = toggle => ({
  type: actionTypes.TOGGLE_DISPATCHED_PIN,
  toggle,
})

export const toggleCoverLevel = payload => ({
  type: actionTypes.TOGGLE_COVERLEVEL,
  payload,
})

export const toggleAdditions = payload => ({
  type: actionTypes.TOGGLE_ADDITIONS,
  payload,
})

export const togglePaymentInterval = payload => ({
  type: actionTypes.TOGGLE_PAYMENT_INTERVAL,
  payload,
})

export const cookieConsent = () => ({
  type: actionTypes.COOKIE_CONSENT,
})
