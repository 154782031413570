import {
  actionTypes,
  fetchingData,
  fetchingFailed,
  updateQuote,
  verifyMobilePin,
  modalFetchingFailed,
  formFetchingFailed,
  formResetError,
  toggleDispatchedPin,
  verifyEmailFailed,
  loadQuoteFailed,
  loadDocumentFailed,
  loadDocumentSuccess,
  youngDriverFailed,
  mileageFailed,
  deductableConceptFailed,
  excessLevelFailed,
} from "./Actions"
import { DispatchRequest } from "../API/QuoteService"
import { navigate } from "@reach/router"
import {
  extractData,
  removeSpaces,
  blobToBase64,
} from "../utils/HelperFunctions"
import { ENDPOINT } from "../API/endpoints"
import { fieldNames } from "../utils/enums"
import { CleanSSN } from "../utils/HelperFunctions"
import { sentryCalcFailed, sentryDocumentFailed } from "../utils/dispatchSentry"
import Cookies from "js-cookie"
import moment from "moment"

export function dispatchMiddleware(state, dispatch) {
  return action => {
    switch (action.type) {
      case actionTypes.CALCULATE_QUOTE:
        dispatch(fetchingData(action))

        DispatchRequest(ENDPOINT.CALCULATE, {
          agreementId: 1,
          inputData: {
            [fieldNames.SSN]: CleanSSN(state.fields.socialSecurity.fieldValue),
            [fieldNames.REG_NR]: removeSpaces(
              state.fields.registrationNumber.fieldValue
            ),
            formatType: "list",
            coverLevel: state.coverLevel,
            excessLevel: state.coverList.excessLevel,
            mileage: state.coverList.mileage,
            deductableConcept: state.coverList.deductableConcept,
            youngDriver: state.additions.youngDriver?.selected,
            optAnimalCollision: state.additions.optAnimalCollision?.selected,
            optHiredCar: state.additions.optHiredCar?.selected,
            optRoadSide: state.additions.optRoadSide?.selected,
            paymentMethod: state.paymentInterval,
          },
        }).then(response => {
          if (response.status === 200) {
            if (response.data.status.code === 0) {
              // All is good
              dispatch(updateQuote(response.data))
            } else {
              // Something is wrong

              if (action.payload === "youngDriver") {
                dispatch(
                  youngDriverFailed(
                    response.data.status.message ||
                      "För tillfället kan vi inte ge dig ett pris, prova igen om en stund."
                  )
                )
              }

              if (action.payload === "mileage") {
                dispatch(
                  mileageFailed(
                    response.data.status.message ||
                      "För tillfället kan vi inte ge dig ett pris, prova igen om en stund."
                  )
                )
              }

              if (action.payload === "deductableConcept") {
                dispatch(
                  deductableConceptFailed(
                    response.data.status.message ||
                      "För tillfället kan vi inte ge dig ett pris, prova igen om en stund."
                  )
                )
              }

              if (action.payload === "excessLevel") {
                dispatch(
                  excessLevelFailed(
                    response.data.status.message ||
                      "För tillfället kan vi inte ge dig ett pris, prova igen om en stund."
                  )
                )
              }

              sentryCalcFailed(state) // Send custom error to sentry
            }
          } else {
            // Something is very wrong
            dispatch(fetchingFailed("Systemet svarar inte."))
          }
        })

        dispatch(action)

        break

      case actionTypes.GENERATE_PIN:
        dispatch(fetchingData(action))
        DispatchRequest(ENDPOINT.GENERATE_PIN, {
          inputData: {
            guid: state.guid,
            [fieldNames.MOBILE]: removeSpaces(
              state.fields.telephoneNumber.fieldValue
            ),
          },
        }).then(response => {
          if (response.status === 200) {
            if (response.data.status.code === 0) {
              // All is good
              dispatch({
                type: action.type,
                guid: response.data.result.guid,
              })

              switch (response.data.result.insType) {
                case "Home":
                  navigate(
                    `/forsakringar/hem/verifiera-mobilnummer?g=${state.guid}`,
                    {
                      replace: true,
                    }
                  )
                  break

                default:
                  navigate(
                    `/bilforsakring/verifiera-mobilnummer?g=${state.guid}`,
                    {
                      replace: true,
                    }
                  )
                  break
              }
            } else {
              // Something is wrong
              dispatch(
                fetchingFailed(
                  response.data.status.message ||
                    "Vi har tekniska problem, prova igen om en stund."
                )
              )
            }
          } else {
            // Something is very wrong
            dispatch(fetchingFailed("Systemet svarar inte."))
          }
        })

        break

      case actionTypes.SAVE_QUOTE_SEND_PIN:
        dispatch(fetchingData(action))

        // Grab affiliates cookies
        let at_gd = JSON.parse(Cookies.get("at_gd") ?? null)
        let sm_re = JSON.parse(Cookies.get("sm_re") ?? null)

        // Edge case if there is 2 cookies set
        if (at_gd && sm_re) {
          if (moment(at_gd.created).isBefore(sm_re.created, "second")) {
            sm_re = null
          } else {
            at_gd = null
          }
        }

        DispatchRequest(ENDPOINT.SAVE_QUOTE_SEND_PIN, {
          agreementId: 1,
          inputData: {
            [fieldNames.SSN]: CleanSSN(state.fields.socialSecurity.fieldValue),
            [fieldNames.REG_NR]: removeSpaces(
              state.fields.registrationNumber.fieldValue
            ),
            [fieldNames.MOBILE]: removeSpaces(
              state.fields.telephoneNumber.fieldValue
            ),
            startDate: state.fields.startDate.fieldValue,
            coverLevel: state.coverLevel,
            excessLevel: state.coverList.excessLevel,
            deductableConcept: state.coverList.deductableConcept,
            mileage: state.coverList.mileage,
            youngDriver: state.additions.youngDriver?.selected,
            optAnimalCollision: state.additions.optAnimalCollision?.selected,
            optHiredCar: state.additions.optHiredCar?.selected,
            optRoadSide: state.additions.optRoadSide?.selected,
            paymentMethod: state.paymentInterval,
            at_gd: at_gd?.payload || "",
            sm_re: sm_re?.payload || "",
          },
        }).then(response => {
          if (response.status === 200) {
            if (response.data.status.code === 0) {
              // All is good
              dispatch({
                type: action.type,
                quote: response.data,
              })

              navigate(`/s?g=${response.data.result.guid}`, {
                replace: true,
              })
            } else {
              // Something is wrong
              dispatch(
                fetchingFailed(
                  response.data.status.message ||
                    "Vi har tekniska problem, prova igen om en stund."
                )
              )
            }
          } else {
            // Something is very wrong
            dispatch(fetchingFailed("Systemet svarar inte."))
          }
        })

        break

      case actionTypes.VERIFY_MOBILE_PIN:
        dispatch(formResetError())
        dispatch(fetchingData(action))

        DispatchRequest(ENDPOINT.PURCHASE_QUOTE, {
          inputData: {
            guid: state.guid,
            pinCode: removeSpaces(state.fields.oneTimePin.fieldValue),
          },
        }).then(response => {
          if (response.status === 200) {
            if (response.data.status.code === 0) {
              // All is good
              dispatch(
                verifyMobilePin({
                  dispatchedPin: true,
                  url: response.data.result.redirectUrl,
                  PaymentInfo: response.data.result.PaymentInfo,
                })
              )

              switch (response.data.result.insType) {
                case "Home":
                  navigate(`/forsakringar/hem/betala?g=${state.guid}`, {
                    replace: true,
                  })
                  break

                default:
                  navigate(`/bilforsakring/betala?g=${state.guid}`, {
                    replace: true,
                  })
                  break
              }
            } else {
              // Something is wrong
              dispatch(verifyMobilePin(false))

              dispatch(
                fetchingFailed(
                  response.data.status.message ||
                    "Vi har tekniska problem, prova igen om en stund."
                )
              )
              dispatch(formFetchingFailed(response.data.status.message))
            }
          } else {
            // Something is very wrong

            dispatch(
              formFetchingFailed(
                "Vi har tyvärr tekniska problem för tillfället. Prova igen om en stund."
              )
            )
          }
        })

        break

      case actionTypes.ACCEPT_QUOTE:
        dispatch(fetchingData(action))

        DispatchRequest(ENDPOINT.CREATE_ACCEPT, {
          agreementId: 1,
          inputData: {
            [fieldNames.SSN]: CleanSSN(state.fields.socialSecurity.fieldValue),
            [fieldNames.REG_NR]: removeSpaces(
              state.fields.registrationNumber.fieldValue
            ),
            [fieldNames.MOBILE]: removeSpaces(
              state.fields.telephoneNumber.fieldValue
            ),
            startDate: state.fields.startDate.fieldValue,
            coverLevel: state.coverList.coverLevel,
            excessLevel: state.coverList.excessLevel,
            mileage: state.coverList.mileage,
            deductableConcept: state.coverList.deductableConcept,
          },
        }).then(response => {
          if (response.status === 200) {
            if (response.data.status.code === 0) {
              // All is good
              window.location.replace(response.data.result.redirectUrl)
            } else {
              // Something is wrong
              dispatch(
                fetchingFailed(
                  response.data.status.message ||
                    "Vi har tekniska problem, prova igen om en stund."
                )
              )
            }
          } else {
            // Something is very wrong
            dispatch(fetchingFailed("Systemet svarar inte."))
          }
        })

        break

      case actionTypes.VERIFY_EMAIL:
        dispatch(fetchingData(action))

        DispatchRequest(ENDPOINT.VERIFY_EMAIL, {
          inputData: {
            emailToken: action.payload,
          },
        }).then(response => {
          if (response.status === 200) {
            if (response.data.status.code === 0) {
              // All is good
              dispatch(action)
            } else {
              // Something is wrong
              dispatch(
                verifyEmailFailed(
                  response.data.status.message ||
                    "Vi har tekniska problem, prova igen om en stund."
                )
              )
            }
          } else {
            // Something is very wrong
            dispatch(fetchingFailed("Systemet svarar inte."))
          }
        })

        break

      case actionTypes.REQUEST_MOBILE_PIN:
        dispatch(fetchingData(action))

        DispatchRequest(ENDPOINT.GENERATE_PIN, {
          inputData: {
            guid: state.guid,
          },
        }).then(result => {
          if (result.status === 200) {
            if (result.data.status.code === 0) {
              // All is good
              dispatch(toggleDispatchedPin(true))
            } else {
              // Something is wrong
              dispatch(toggleDispatchedPin(false))
            }
          } else {
            // Something is very wrong
            dispatch(verifyMobilePin(false))
            dispatch(
              modalFetchingFailed("Något gick fel, vänligen försök igen.")
            )
          }
        })
        break

      case actionTypes.LOAD_DOCUMENT:
        dispatch(fetchingData())

        DispatchRequest(ENDPOINT.FETCH_DOCUMENT, action.payload.id).then(
          response => {
            if (response.status === 200) {
              const blob = new Blob([response.data], {
                type: "application/pdf",
              })

              try {
                navigate(
                  (
                    window.URL ||
                    window.webkitURL ||
                    window ||
                    {}
                  ).createObjectURL(blob),
                  { replace: true }
                )
                ;(async () => {
                  const b64 = await blobToBase64(blob)
                  dispatch(loadDocumentSuccess(JSON.stringify(b64)))
                })()
              } catch (err) {
                ;(async () => {
                  const b64 = await blobToBase64(blob)
                  dispatch(loadDocumentSuccess(JSON.stringify(b64)))
                })()

                // dispatch(loadDocumentFailed())
                sentryDocumentFailed(action.payload.id, JSON.stringify(err))
              }
            } else {
              // Something is very wrong
              dispatch(loadDocumentFailed())
              sentryDocumentFailed(action.payload.id)
            }
          }
        )

        break

      case actionTypes.LOAD_QUOTE:
        DispatchRequest(ENDPOINT.GET_QUOTE, action.payload.guid).then(
          response => {
            if (response.status === 200) {
              if (response.data.status.code === 0) {
                // All is good

                if (response.data.result.redirectUrl !== undefined) {
                  dispatch({
                    type: action.type,
                    result: response.data.result,
                  })
                } else {
                  dispatch({
                    type: action.type,
                    guid: action.payload.guid,
                    result: response.data.result,
                  })
                }
              } else {
                // Something is wrong
                dispatch(
                  loadQuoteFailed({
                    message: response.data.status.message,
                    code: response.data.status.code,
                  })
                )
              }
            } else {
              // Something is very wrong

              dispatch(
                loadQuoteFailed({
                  message:
                    "Just nu felsöker vi och försöker åtgärda problemet.",
                  code: 10,
                })
              )
            }
          }
        )

        break

      default:
        return dispatch(action)
    }
  }
}

export const quoteReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.HAS_FETCHED_QUOTE:
      return {
        ...state,
        hasLoadedQuote: false,
      }
    case actionTypes.LOAD_DOCUMENT:
      return {
        ...state,
        hasFetchedDocument: true,
        isLoading: false,
        document: {
          isLoaded: true,
          content: action.payload,
          message: "",
        },
      }
    case actionTypes.LOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        hasFetchedDocument: true,
        isLoading: false,
        document: {
          failed: false,
          isLoaded: true,
          content: action.payload,
          message: "",
        },
      }
    case actionTypes.LOAD_DOCUMENT_FAILED:
      return {
        ...state,
        hasFetchedDocument: true,
        isLoading: false,
        document: {
          failed: true,
          isLoaded: false,
          content: {},
          message: action.payload,
        },
      }
    case actionTypes.VERIFY_EMAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        verifyEmail: {
          verified: false,
          message: action.payload,
          hasFetched: true,
        },
      }
    case actionTypes.VERIFY_EMAIL:
      return {
        ...state,
        isLoading: false,
        verifyEmail: {
          verified: true,
          message: "",
          hasFetched: true,
        },
      }
    case actionTypes.LOAD_QUOTE:
      return {
        ...state,
        guid: action.guid,
        redirectUrl: action.result.redirectUrl || "",
        hasLoadedQuote: true,
        hasFetchedQuote: true,
        PaymentInfo: action.result.PaymentInfo,
        loadedQuote: {
          result: action.result,
        },
        quote: { result: action.result },
        hasQuote: false,
        fields: {
          ...state.fields,
          telephoneNumber: {
            fieldValue: action.result.telephoneNumber,
          },
        },
      }
    case actionTypes.LOAD_QUOTE_FAILED:
      return {
        ...state,
        hasFetchedQuote: true,
        loadedQuoteFailed: {
          message: action.payload.message,
          failed: true,
          code: action.payload.code,
        },
      }

    case actionTypes.TOGGLE_PAYMENT_INTERVAL:
      return {
        ...state,
        paymentInterval: action.payload,
      }

    case actionTypes.TOGGLE_ADDITIONS:
      return {
        ...state,
        additions: {
          ...state.additions,
          [action.payload]: {
            ...state.additions[action.payload],
            selected: !state.additions[action.payload].selected,
          },
        },
      }

    case actionTypes.TOGGLE_COVERLEVEL:
      switch (action.payload) {
        case "Hel":
          return {
            ...state,
            coverLevel: action.payload,
            prevCoverLevel: "",
          }
        case "Halv":
          return {
            ...state,
            coverLevel: action.payload,
            prevCoverLevel: "",
          }
        case "Trafik":
          return {
            ...state,
            coverLevel: action.payload,
            prevCoverLevel: state.coverLevel,
          }
        default:
          break
      }
      break
    case actionTypes.TOGGLE_DISPATCHED_PIN:
      return {
        ...state,
        dispatchedPin: action.toggle,
        isLoading: false,
      }
    case actionTypes.VERIFY_MOBILE_PIN:
      return {
        ...state,
        dispatchedPin: action.payload.dispatchedPin,
        redirectUrl: action.payload.url,
        PaymentInfo: action.payload.PaymentInfo,
        // isLoading: false,
        fields: {
          ...state.fields,
          oneTimePin: {
            valid: false,
            fieldValue: "",
            dirty: false,
            message: "",
          },
        },
      }
    case actionTypes.SAVE_QUOTE_SEND_PIN:
      return {
        ...state,
        guid: action.quote.result.guid,
        quote: action.quote,
      }
    case actionTypes.UPDATE_FIELD:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.name]: {
            fieldValue: action.payload.value,
            message: action.payload.message,
            valid: action.payload.valid,
            dirty: action.payload.dirty,
          },
        },
      }
    case actionTypes.UPDATE_COVERLIST: {
      return {
        ...state,
        coverList: {
          ...state.coverList,
          [action.payload.name]: action.payload.value,
        },
      }
    }
    case actionTypes.INIT_FETCH_QUOTE:
      return {
        ...state,
        initFetchQuote: action.payload,
        error: !state.error,
      }
    case actionTypes.FETCHING_DATA:
      return {
        ...state,
        isLoading: true,
        error: !state.error,
        calcErrors: {
          youngDriver: "",
          mileage: "",
          excessLevel: "",
          deductableConcept: "",
        },
      }
    case actionTypes.FETCHING_FAILED:
      return {
        ...state,
        isLoading: false,
        initFetchQuote: false,
        error: action.error,
      }
    case actionTypes.EXCESS_LEVEL_FAILED:
      return {
        ...state,
        isLoading: false,
        initFetchQuote: false,
        calcErrors: {
          excessLevel: action.payload,
          youngDriver: "",
          mileage: "",
          deductableConcept: "",
        },
      }
    case actionTypes.YOUNG_DRIVER_FAILED:
      return {
        ...state,
        isLoading: false,
        initFetchQuote: false,
        calcErrors: {
          youngDriver: action.payload,
          mileage: "",
          excessLevel: "",
          deductableConcept: "",
        },
      }
    case actionTypes.MILEAGE_FAILED:
      return {
        ...state,
        isLoading: false,
        initFetchQuote: false,
        calcErrors: {
          youngDriver: "",
          mileage: action.payload,
          excessLevel: "",
          deductableConcept: "",
        },
      }
    case actionTypes.DEDUCTABLE_CONCEPT_FAILED:
      return {
        ...state,
        isLoading: false,
        initFetchQuote: false,
        calcErrors: {
          youngDriver: "",
          mileage: "",
          excessLevel: "",
          deductableConcept: action.payload,
        },
      }
    case actionTypes.RECALC_FAILED:
      return {
        ...state,
        isLoading: false,
        initFetchQuote: false,
      }
    case actionTypes.FORM_FETCHING_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          formError: action.error,
        },
      }
    case actionTypes.MODAL_FETCHING_FAILED:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          modalError: action.error,
        },
      }
    case actionTypes.FORM_RESET_ERROR:
      return {
        ...state,
        isLoading: false,
        error: "",
        errors: {
          ...state.errors,
          formError: "",
        },
      }
    case actionTypes.MODAL_RESET_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          modalError: "",
        },
      }
    case actionTypes.UPDATE_QUOTE:
      let coverpack = extractData(
        action.quote.result.settings.CoverPack,
        "additions",
        "coverLevel"
      )

      var additions = Object.assign(
        ...coverpack.coverDetail.map(k => ({
          [k.paramName]: {
            selected: k.selected,
            mValue: k.mValue,
            id: k.id,
          },
        }))
      )

      return {
        ...state,
        quote: action.quote,
        coverList: {
          mileage: action.quote.result.mileage,
          deductableConcept: action.quote.result.deductableConcept,
          excessLevel: action.quote.result.excessLevel,
          coverLevel: action.quote.result.coverLevel,
        },
        isLoading: false,
        hasQuote: true,
        step: 2,
        additions,
        coverLevel: state.initFetchQuote
          ? action.quote.result.recommendCover
          : action.quote.result.coverLevel,
        fields: {
          ...state.fields,
          startDate: {
            fieldValue: "2010-10-10", // Old date is needed to prompt datepicker-page
            message: "",
            valid: false,
            dirty: false,
          },
        },
        prevCoverLevel: action.quote.result.recommendCover,
      }
    case actionTypes.TOGGLE_MENU:
      return {
        ...state,
        showMenu: action.open,
      }
    case actionTypes.VERIFY_MOBILE:
      return {
        ...state,
        isLoading: !state.isLoading,
        mobileVerified: {
          verified: action.payload,
          [fieldNames.SSN]: CleanSSN(state.fields.socialSecurity.fieldValue),
          [fieldNames.REG_NR]: removeSpaces(
            state.fields.registrationNumber.fieldValue
          ),
        },
      }
    case actionTypes.COOKIE_CONSENT:
      return {
        ...state,
        cookieConsent: true,
      }
    default:
      return state
  }
}
