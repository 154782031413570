// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bilforsakring-ange-mobilnummer-js": () => import("./../../../src/pages/bilforsakring/ange-mobilnummer.js" /* webpackChunkName: "component---src-pages-bilforsakring-ange-mobilnummer-js" */),
  "component---src-pages-bilforsakring-bekraftelse-js": () => import("./../../../src/pages/bilforsakring/bekraftelse.js" /* webpackChunkName: "component---src-pages-bilforsakring-bekraftelse-js" */),
  "component---src-pages-bilforsakring-betala-js": () => import("./../../../src/pages/bilforsakring/betala.js" /* webpackChunkName: "component---src-pages-bilforsakring-betala-js" */),
  "component---src-pages-bilforsakring-index-js": () => import("./../../../src/pages/bilforsakring/index.js" /* webpackChunkName: "component---src-pages-bilforsakring-index-js" */),
  "component---src-pages-bilforsakring-lagg-till-mobilnummer-js": () => import("./../../../src/pages/bilforsakring/lagg-till-mobilnummer.js" /* webpackChunkName: "component---src-pages-bilforsakring-lagg-till-mobilnummer-js" */),
  "component---src-pages-bilforsakring-lagg-till-startdag-js": () => import("./../../../src/pages/bilforsakring/lagg-till-startdag.js" /* webpackChunkName: "component---src-pages-bilforsakring-lagg-till-startdag-js" */),
  "component---src-pages-bilforsakring-offert-js": () => import("./../../../src/pages/bilforsakring/offert.js" /* webpackChunkName: "component---src-pages-bilforsakring-offert-js" */),
  "component---src-pages-bilforsakring-valj-startdag-js": () => import("./../../../src/pages/bilforsakring/valj-startdag.js" /* webpackChunkName: "component---src-pages-bilforsakring-valj-startdag-js" */),
  "component---src-pages-bilforsakring-verifiera-mobilnummer-js": () => import("./../../../src/pages/bilforsakring/verifiera-mobilnummer.js" /* webpackChunkName: "component---src-pages-bilforsakring-verifiera-mobilnummer-js" */),
  "component---src-pages-bilforsakring-verifiera-pinkod-js": () => import("./../../../src/pages/bilforsakring/verifiera-pinkod.js" /* webpackChunkName: "component---src-pages-bilforsakring-verifiera-pinkod-js" */),
  "component---src-pages-compricer-index-js": () => import("./../../../src/pages/compricer/index.js" /* webpackChunkName: "component---src-pages-compricer-index-js" */),
  "component---src-pages-compricer-teckna-js": () => import("./../../../src/pages/compricer/teckna.js" /* webpackChunkName: "component---src-pages-compricer-teckna-js" */),
  "component---src-pages-dokument-js": () => import("./../../../src/pages/dokument.js" /* webpackChunkName: "component---src-pages-dokument-js" */),
  "component---src-pages-forkopsinformation-index-js": () => import("./../../../src/pages/forkopsinformation/index.js" /* webpackChunkName: "component---src-pages-forkopsinformation-index-js" */),
  "component---src-pages-forsakring-bekraftelse-js": () => import("./../../../src/pages/forsakring/bekraftelse.js" /* webpackChunkName: "component---src-pages-forsakring-bekraftelse-js" */),
  "component---src-pages-forsakringar-hem-bekraftelse-js": () => import("./../../../src/pages/forsakringar/hem/bekraftelse.js" /* webpackChunkName: "component---src-pages-forsakringar-hem-bekraftelse-js" */),
  "component---src-pages-forsakringar-hem-betala-js": () => import("./../../../src/pages/forsakringar/hem/betala.js" /* webpackChunkName: "component---src-pages-forsakringar-hem-betala-js" */),
  "component---src-pages-forsakringar-hem-index-js": () => import("./../../../src/pages/forsakringar/hem/index.js" /* webpackChunkName: "component---src-pages-forsakringar-hem-index-js" */),
  "component---src-pages-forsakringar-hem-lagg-till-mobilnummer-js": () => import("./../../../src/pages/forsakringar/hem/lagg-till-mobilnummer.js" /* webpackChunkName: "component---src-pages-forsakringar-hem-lagg-till-mobilnummer-js" */),
  "component---src-pages-forsakringar-hem-verifiera-mobilnummer-js": () => import("./../../../src/pages/forsakringar/hem/verifiera-mobilnummer.js" /* webpackChunkName: "component---src-pages-forsakringar-hem-verifiera-mobilnummer-js" */),
  "component---src-pages-forsakringar-hem-verifiera-pinkod-js": () => import("./../../../src/pages/forsakringar/hem/verifiera-pinkod.js" /* webpackChunkName: "component---src-pages-forsakringar-hem-verifiera-pinkod-js" */),
  "component---src-pages-forsakringar-produkt-index-js": () => import("./../../../src/pages/forsakringar/produkt/index.js" /* webpackChunkName: "component---src-pages-forsakringar-produkt-index-js" */),
  "component---src-pages-forsakringsvillkor-index-js": () => import("./../../../src/pages/forsakringsvillkor/index.js" /* webpackChunkName: "component---src-pages-forsakringsvillkor-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insplanet-index-js": () => import("./../../../src/pages/insplanet/index.js" /* webpackChunkName: "component---src-pages-insplanet-index-js" */),
  "component---src-pages-insplanet-teckna-js": () => import("./../../../src/pages/insplanet/teckna.js" /* webpackChunkName: "component---src-pages-insplanet-teckna-js" */),
  "component---src-pages-om-formedling-js": () => import("./../../../src/pages/om-formedling.js" /* webpackChunkName: "component---src-pages-om-formedling-js" */),
  "component---src-pages-om-oss-index-js": () => import("./../../../src/pages/om-oss/index.js" /* webpackChunkName: "component---src-pages-om-oss-index-js" */),
  "component---src-pages-om-oss-jobb-och-karriar-haskell-developer-js": () => import("./../../../src/pages/om-oss/jobb-och-karriar/haskell-developer.js" /* webpackChunkName: "component---src-pages-om-oss-jobb-och-karriar-haskell-developer-js" */),
  "component---src-pages-om-oss-jobb-och-karriar-index-js": () => import("./../../../src/pages/om-oss/jobb-och-karriar/index.js" /* webpackChunkName: "component---src-pages-om-oss-jobb-och-karriar-index-js" */),
  "component---src-pages-om-oss-jobb-och-karriar-react-developer-js": () => import("./../../../src/pages/om-oss/jobb-och-karriar/react-developer.js" /* webpackChunkName: "component---src-pages-om-oss-jobb-och-karriar-react-developer-js" */),
  "component---src-pages-p-js": () => import("./../../../src/pages/p.js" /* webpackChunkName: "component---src-pages-p-js" */),
  "component---src-pages-personuppgifter-js": () => import("./../../../src/pages/personuppgifter.js" /* webpackChunkName: "component---src-pages-personuppgifter-js" */),
  "component---src-pages-produktfaktablad-index-js": () => import("./../../../src/pages/produktfaktablad/index.js" /* webpackChunkName: "component---src-pages-produktfaktablad-index-js" */),
  "component---src-pages-s-js": () => import("./../../../src/pages/s.js" /* webpackChunkName: "component---src-pages-s-js" */),
  "component---src-pages-service-change-payment-complete-js": () => import("./../../../src/pages/service/change-payment-complete.js" /* webpackChunkName: "component---src-pages-service-change-payment-complete-js" */),
  "component---src-pages-service-change-payment-js": () => import("./../../../src/pages/service/change-payment.js" /* webpackChunkName: "component---src-pages-service-change-payment-js" */),
  "component---src-pages-teckna-js": () => import("./../../../src/pages/teckna.js" /* webpackChunkName: "component---src-pages-teckna-js" */),
  "component---src-pages-verify-js": () => import("./../../../src/pages/verify.js" /* webpackChunkName: "component---src-pages-verify-js" */),
  "component---src-pages-villkor-js": () => import("./../../../src/pages/villkor.js" /* webpackChunkName: "component---src-pages-villkor-js" */)
}

