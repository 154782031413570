import React, { createContext, useReducer } from "react"
import { quoteReducer, dispatchMiddleware } from "./QuoteReducer"

import { ThemeProvider } from "emotion-theming"
import { defaultTheme } from "../utils/Themes"
import { getCookieConsent } from "../utils/IsDarkTheme"
import { fieldNames } from "../utils/enums"

export const QuoteContext = createContext()

function getLocalStorageItem(key) {
  if (typeof window === "undefined") {
    return ""
  }

  return localStorage.getItem(key) || ""
}

export const initialStateQuote = {
  fields: {
    [fieldNames.REG_NR]: {
      fieldValue: getLocalStorageItem("rnr"),
      message: "",
      valid: false,
      dirty: false,
    },
    [fieldNames.SSN]: {
      fieldValue: getLocalStorageItem("pnr"),
      message: "",
      valid: false,
      dirty: false,
    },
    email: { fieldValue: "", message: "", valid: false, dirty: false },
    [fieldNames.MOBILE]: {
      fieldValue: "",
      message: "",
      valid: false,
      dirty: false,
    },
    startDate: {
      fieldValue: "2010-10-10", // Old date is needed to prompt datepicker-page
      message: "",
      valid: false,
      dirty: false,
    },
    [fieldNames.PIN]: {
      fieldValue: "",
      message: "",
      valid: false,
      dirty: false,
    },
  },
  coverList: {
    mileage: "1000",
    deductableConcept: "High",
    excessLevel: "",
    coverLevel: "",
  },
  additions: {},
  quote: {},
  coverLevel: "Hel",
  prevCoverLevel: "",
  guid: "",
  hasQuote: false,
  step: 1,
  isLoading: false,
  initFetchQuote: false,
  error: "",
  showMenu: false,
  dispatchedPin: false,
  redirectUrl: "",
  mobileVerified: {
    verified: false,
    socialSecurity: "",
    telephoneNumber: "",
  },
  errors: {
    modalError: "",
    formError: "",
  },
  coverBoxes: {
    Hel: true,
    Halv: true,
    Trafik: true,
  },
  loadedQuoteFailed: { message: "", code: 0, failed: false },
  hasLoadedQuote: false,
  hasFetchedQuote: false,
  loadedQuote: {},
  PaymentInfo: {},
  verifyEmail: {
    verified: false,
    message: "",
    hasFetched: false,
  },
  hasFetchedDocument: false,
  document: {
    failed: false,
    isLoaded: false,
    content: {},
    message: "",
  },
  cookieConsent: getCookieConsent(),
  calcErrors: {
    youngDriver: "",
    mileage: "",
    excessLevel: "",
    deductableConcept: "",
  },
  paymentInterval: "Monthly",
  deductableConcept: "High",
}

export const QuoteProvider = props => {
  const [state, dispatch] = useReducer(quoteReducer, initialStateQuote)
  return (
    <QuoteContext.Provider
      value={{ state, dispatch: dispatchMiddleware(state, dispatch) }}
    >
      <ThemeProvider theme={defaultTheme}>{props.children}</ThemeProvider>
    </QuoteContext.Provider>
  )
}
