export const fieldNames = {
  REG_NR: "registrationNumber",
  SSN: "socialSecurity",
  MOBILE: "telephoneNumber",
  PIN: "oneTimePin",
  EMAIL: "email",
}

export const apiUsers = {
  EVOLI: {
    id: 1,
    name: "evoli",
  },
  INSPLANET: {
    id: 2,
    name: "Insplanet",
  },
  COMPRICER: {
    id: 3,
    name: "Compricer",
  },
}
