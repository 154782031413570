export function extractData(data, key, property) {
  return data.find(obj => {
    return obj[property] === key
  })
}

export function CleanSSN(ssn) {
  ssn = ssn
    .trim()
    .replace(/\s/g, "")
    .replace("-", "")

  if (ssn.length >= 12) {
    return ssn.substring(2)
  } else {
    return ssn
  }
}

export function removeSpaces(string) {
  return string.trim().replace(/\s/g, "")
}

export const isGuid = guid => {
  const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

  return regex.test(guid)
}

export function isNullOrWhitespace(input) {
  return !input || !input.trim()
}

export const blobToBase64 = blob => {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = function() {
      resolve(reader.result)
    }
  })
}

export function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}
